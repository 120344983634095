<template>
  <div class="home-banner-container">
    <div class="home-banner-content">
      <h1 class="title-home-0">A better way to grow your money.</h1>
      <div class="sub-title-home">Annuities built for the 21st century.</div>
      <BannerCard />
    </div>
  </div>
</template>

<script>

import BannerCard from "./bannercard/BannerCard";

export default {
  name: "GainbridgeProtect",
  components: {
    BannerCard,
  },
  props: ['href']
}
</script>

<style>
.home-banner-container {
  background-color: #FAF7F4;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
  gap: 24px;
  /* width: 100% */
}

.home-banner-content {
  padding: 40px 16px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1440px;
}

.title-home-0 {
  color: var(--black);
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 40px;
  font-weight: 700;
  /*left: 172px;*/
  letter-spacing: 0;
  line-height: 51.2px;
  position: relative;
  margin-top: 40px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.sub-title-home{
  color: var(--black-2);
  font-family: var(--font-family-aktiv_grotesk-regular);
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
  position: relative;
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
}

.home-banner-mobile {
  display: block;
}

.home-banner-desktop {
  display: none;
}

.home-banner-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
}

.home-banner-header {
  color: var(--black);
  text-align: center;
  font-family: var(--font-family-aktiv_grotesk-bold);
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}

@media (min-width: 960px) {
  .home-banner-header {
    font-size: 60px;
  }

  .home-banner-header-container {
    max-width: 658px;
  }

  .home-banner-mobile {
    display: none;
  }

  .home-banner-desktop {
    display: block
  }

  .title-home-0 {
    color: var(--black);
    font-family: var(--font-family-aktiv_grotesk-bold);
    font-size: 64px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 70px;
    position: relative;
    text-align: center;
    justify-content: center;
    display: flex;
    gap: 7px;
  }

  .sub-title-home{
    color: var(--black-2);
    font-family: var(--font-family-aktiv_grotesk-regular);
    font-size: 24px;
    font-weight: 500;
    line-height: 38px;
    position: relative;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
  }
}

@media (min-width: 1120px) {

  .home-banner-content {
    padding: 60px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .home-banner-header-container {
    width: fit-content;
    max-width: 100%;
  }

}

@media (min-width: 1301px) {

  .home-banner-content {
    flex-direction: row;
    justify-content: center;
  }

  .home-banner-header {
    font-size: 80px;
    line-height: 80px;
    max-width: 500px;
    text-align: left;
  }
}
</style>