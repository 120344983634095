<template>
  <div class="banner-container">
    <div class="banner-card-group">
      <div class="banner-card-container banner-card-rounding-1">
        <div class="banner-content">
          <h2 class="banner-card-title">FastBreak™</h2>
          <p class="banner-card-subtitle">A rate that beats most banks.</p>
          <ul class="banner-card-details-home extra-margin">
            <li>Earn a 4x higher yield than the average CD.<a href="#footnote-home-1"><sup>1</sup></a></li>
            <li>Locked-in rates (vs. variable APY with savings accounts).</li>
            <li>Taxes paid annually on interest earned.</li>
          </ul>
        </div>
        <StartButton :link="fastbreakLink" text="Start FastBreak™" />
      </div>
      <div class="banner-card-container banner-card-rounding-0">
        <div class="banner-content">
          <h2 class="banner-card-title">SteadyPace™</h2>
          <p class="banner-card-subtitle">Up your retirement savings pace.</p>
          <ul class="banner-card-details-home extra-margin">
            <li v-if="!showNewRate">Earn a guaranteed {{ steadypaceAPY }}% APY.<a
                href="#footnote-home-2"><sup>2</sup></a></li>
            <li v-if="showNewRate">Earn a guaranteed rate up to {{ steadypaceAPY }}% APY.<a
                href="#footnote-home-2"><sup>2</sup></a></li>
            <li>A safe way to complement your retirement portfolio.</li>
            <li>Tax-deferred: No income taxes until you withdraw funds.</li>
          </ul>
        </div>
        <StartButton :link="steadypaceLink" text="Start SteadyPace™" />
      </div>
      <div class="banner-card-container banner-card-rounding-0">
        <div class="banner-content">
          <h2 class="banner-card-title">ParityFlex™</h2>
          <p class="banner-card-subtitle">Retirement money you can't outlive - the ultimate flex.</p>
          <ul class="banner-card-details-home extra-margin">
            <li>Turn your investment into a paycheck for life.</li>
            <li>Guaranteed income even if your account reaches zero.<a href="#footnote-home-3"><sup>3</sup></a></li>
            <li>You're in control with flexible withdrawals.</li>
          </ul>
        </div>
        <StartButton :link="parityflexLink" text="Start ParityFlex™" />
      </div>
      <div class="banner-card-container banner-card-rounding-2">
        <div class="banner-content">
          <h2 class="banner-card-title">OneUp™</h2>
          <p class="banner-card-subtitle">
            S&P 500 upside, plus 1%.
          </p>
          <ul class="banner-card-details-home extra-margin">
            <li>Market growth potential for your retirement nest egg.</li>
            <li>Tax-deferred: No income taxes until you withdraw funds.</li>
            <li>Our OneUp™ is a registered index-linked annuity (RILA).<a href="#footnote-home-4"><sup>4</sup></a></li>
          </ul>
        </div>
        <StartButton :link="oneupLink" text="Start OneUp™" />
      </div>
    </div>
  </div>
</template>

<script>
import { initializeLaunchDarkly } from "@/launchDarkly";
import { getEnrollmentUrl } from "../../../../components/utils";
import { getSteadyPaceRate, ratesFeatureFlag } from "../../../../rates";
import StartButton from "./StartButton";
export default {
  name: "BannerCard",
  components: {
    StartButton,
  },
  mounted() {
    initializeLaunchDarkly(ratesFeatureFlag, this.onFfRetrieval);
  },
  data() {
    return {
      showNewRate: false,
      steadypaceAPY: getSteadyPaceRate(),
      steadypaceLink: getEnrollmentUrl('steadypace'),
      fastbreakLink: getEnrollmentUrl('fastbreak'),
      parityflexLink: getEnrollmentUrl('parityflex'),
      oneupLink: getEnrollmentUrl('oneup'),
    }
  },
  methods: {
    onFfRetrieval(featureFlag) {
      this.steadypaceAPY = getSteadyPaceRate(featureFlag);
      this.showNewRate = featureFlag;
    },
  }
}
</script>

<style>
.banner-card-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

@media (max-width: 1400px) {
  .banner-card-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

.banner-card-container {
  border-radius: 3px;
  height: auto;
  max-width: 321px;
  padding: 24px;
  position: relative;
  margin-right: 0px;
  background-color: #FFFFFF;
  width: 321px;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.06), 0px 2px 2px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.banner-card-rounding-1 {
  border-top-left-radius: 30px;
}

.banner-card-rounding-0 {
  border-radius: 8px;
}

.banner-card-rounding-2 {
  border-bottom-right-radius: 30px;
}

.banner-card-title {
  color: #020102;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8px;
  font-family: var(--font-family-aktiv_grotesk-bold);
}

.banner-card-subtitle {
  color: var(--black-2);
  font-size: 16px;
  margin-bottom: 24px;
  font-family: var(--font-family-aktiv_grotesk-normal);
  font-weight: 400;
  max-width: 279px;
}

.banner-card-details-home {
  color: #434A55;
  padding-left: 32px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.banner-card-details-home li {
  list-style-type: none;
  margin-left: -32px;
  padding-left: 27px;
  background: url("~@/assets/images/home/checkmark.svg") no-repeat left;
  background-size: 20px;
  background-position-y: 0px;
  font-family: var(--font-family-aktiv_grotesk-regular);
  max-width: 259px;
}

.banner-card-details-home a {
  text-decoration: none;
  color: #434A55;
}

.banner-card-emphasis {
  text-decoration: none;
  color: #1040B3;
  font-weight: 500;
  font-size: 14px;
}

.extra-margin {
  margin-bottom: 24px;
}

@media (max-width: 960px) {
  .banner-card-group {
    flex-direction: column;
    display: flex;
  }

  .banner-card-container {
    width: 100%;
    max-width: 600px;
  }

  .banner-card-rounding-0 {
    border-radius: 30px 3px 30px 3px;
  }

  .banner-card-rounding-1 {
    border-bottom-right-radius: 30px;
  }

  .banner-card-rounding-2 {
    border-top-left-radius: 30px;
  }
}
</style>